import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../axios-auth';
import createBatch from "./modules/createBatch";
import customText from "./modules/customText";
import Cookies from 'js-cookie';

import createPersistedState from "vuex-persistedstate";
import router from "../router";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        idToken: null,
        userId: null,
        user: null,
        api: null,
        APIKey: null,
        authError: null,
        loadingProcess: false,
        fontStyleToolbarActive: false,
        userDetails: {}
    },
    mutations: {
        setUserDetails(state, payload) {
            state.userDetails = payload
        },
        toggleLoadingProcess(state, val) {
            state.loadingProcess = val;
        },
        setAuthTokenInfo(state, data) {
            state.idToken = data.accessToken;
            state.userId = data.userId;
            Cookies.set('token', data.accessToken);
            Cookies.set('userId', data.userId);
        },
        authUser(state, userData) {
            state.idToken = userData.token;
            state.userId = userData.userId;
        },
        storeUser(state, user) {
            state.user = user
        },
        clearAuthData(state) {
            state.idToken = null;
            state.userId = null;
        },
        errorMessage(state, error) {
            state.authError = error
        },
        // toogleFontStyleToolbarActive(state) {
        //     state.fontStyleToolbarActive = !state.fontStyleToolbarActive;
        // }
    },
    actions: {
        checkDuplicateCard({state}, payload) {
            return axios.get(`/api/payments/check-duplicate?id=${payload}`,
                {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        setLogoutTimer({commit, dispatch}, expirationTime) {
            setTimeout(() => {
                // dispatch('resetCreateBatchProcess')
                commit('clearAuthData')
            }, expirationTime * 1000)
        },
        signUp({commit, dispatch}, authData) {
            return axios.post('/auth/register/email', authData)
        },
        authenticate({commit, dispatch}, authData) {
            return axios.get('/auth/validate/account', {
                headers:
                    {'Authorization': `JWT ${authData.tokenId}`}
            })
        },
        setToken({commit, dispatch}, tokenData) {
            commit('authUser', {
                token: tokenData.token,
                userId: tokenData.userId
            });
            const now = new Date();
            const expiryDate = new Date(now.getTime() + tokenData.expiry * 1000);
            Cookies.set('token', tokenData.token);
            Cookies.set('userId', tokenData.userId);
            Cookies.set('expirationDate', expiryDate);
            dispatch('setLogoutTimer', tokenData.expiry);
        },
        login({commit, dispatch}, authData) {
            return axios.post('/auth/login/email', authData)
        },
        googleAuthSignUp({state}, data) {
            return axios.post('/auth/register/google', {data: data})
        },
        googleAuthLogin({state}, data) {
            return axios.post('/auth/login/google', {data: data})
        },
        resetPassword({commit, dispatch}, email) {
            return axios.post('/auth/reset-password', email)
        },
        resendLink({commit, dispatch}, data) {
            return axios.post('/auth/resend/verification-token', data)
        },
        changePassword({commit, dispatch}, data) {
            return axios.post('/auth/change-password', {
                newPassword: data.newPassword,
                confirmPassword: data.confirmPassword,
                token: data.token
            }, {
                headers:
                    {'Authorization': `JWT ${data.tokenId}`}
            })
        },
        supportEmail({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/contact-support', {
                data: data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        changeEmail({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/users/change-email', {
                code: data.code,
                task: data.task,
                email: data.email
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        deleteBatch({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/users/batch/delete', {batchID: data},
                {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        deleteUserAccount({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/users/account/delete', data,
                {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        verifyValidationCode({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/user/validation-code', {
                code: data.code,
                task: data.task
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        validateNewEmail({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/validation/send-code/change-email', {
                email: data.email,
                task: data.task
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        changeUserPassword({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/users/change-password', {
                newPassword: data.newPassword,
                confirmPassword: data.confirmPassword,
                loginType: data.loginType,
                oldPassword: data.oldPassword
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        saveUserDetails({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/users/profile/info', {
                payload: data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        saveBillingDetails({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
          
            return axios.post('/users/profile/billing', {
                ...data,
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        removePaymentMethod({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/remove/payment-method', {
                payload: data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        createCustomer({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/create/customer', {
                ...data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        createPayment({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/create/payment', {
                payload: data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        createPaymentIntent({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/create/payment-intent', {
                payload: data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        sendEmail({state}, data) {
            return axios.post('/sendEmail', {data: data})
        },
        verifyPassword({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/users/verify-password', {
                password: data.password
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        verifyAuthenticatorCode({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/mfa/verify/authenticator-code', {
                code: data.code
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        verifyEmailCode({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/mfa/verify/email-validation-code', {
                code: data.code
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        validateBackUpCode({commit, dispatch}, data) {
            return axios.post('/mfa/validate/backup-code', {
                code: data.code,
                remember: data.remember,
                token: data.recaptcha,
            }, {headers: {'Authorization': `JWT ${data.token}`}})
        },
        verifySecurityCheck({commit, dispatch}, data) {
            return axios.post('/mfa/verify/security-check', {
                code: data.code,
                remember: data.remember,
                method: data.method,
                token: data.recaptcha,
            }, {headers: {'Authorization': `JWT ${data.token}`}})
        },
        disableMFA({state, dispatch}) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.get('/mfa/disable',
                {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        paddleCheckout({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/payments/checkout', data,
                {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        sendEmailValidationCode({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.get('/mfa/send/email-validation-code',
                {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        setValidationCode({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/user/validation/send-code', {
                    task: data.task
                },
                {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        resendEmailValidationCode({commit, dispatch}, data) {
            return axios.post('/mfa/resend/email-validation-code', {
                task: 'mfa-security-check'
            }, {headers: {'Authorization': `JWT ${data.token}`}})
        },
        verifyRememberToken({commit, dispatch}, data) {
            return axios.get('/verify/remember-token',
                {headers: {'Authorization': `JWT ${data.token}`}})
        },
        tryAutoLogin({commit, dispatch}) {
            const token = Cookies.get("token");
            if (!token) {
                // console.log('here')
                // dispatch('logout', {message: 'Please login again'});
            } else {
                const expirationDate =  Cookies.get("expirationDate");
                const now = new Date();
                if (now >= expirationDate) {
                    dispatch('logout', {message: 'Please login again'});
                } else {

                    const userId = Cookies.get("userId")
                    commit('authUser', {
                        token: token,
                        userId: userId
                    });
                    router.push({
                        path: 'dashboard/batches'
                    });
                }
            }
        },
        logout({state, commit, dispatch}, data) {
            axios.post('/logout', {
                token: state.idToken
            })
            dispatch('resetCreateBatchProcess');
            commit('clearAuthData');
            Cookies.remove('expirationDate');
            Cookies.remove('token');
            Cookies.remove('userId');
            Cookies.remove('vuex');
            router.push({name: 'login', params: {redirectMessage: data.message}})
        },
        fetchAPI({state, dispatch}) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.get('/fetch/user',
                {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        fetchQRCode({state, dispatch}) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.get('/mfa/fetch/qr-code',
                {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        getCredits({state, dispatch}) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.get('/users/credits',
                {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        getPaymentMethods({state, dispatch}) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.get('/fetch/payment-methods',
                {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        getOrders({state, dispatch}) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.get('/fetch/orders',
                {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        saveBatch({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/save/batch', {
                data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        getAllBatches({state, dispatch}) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.get('/users/batches', {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        downloadBatch({state, dispatch}, batchID) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.get(`/api/batch/download/${batchID}`
            , {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        applyCoupon({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/coupons', {
                coupon_code: data.coupon_code
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        reviewData({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/save/reviewed-data', {
                data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        addCodesDetail({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/save/codes-detail', {
                data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        addInlineEditorData({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/save/inline-data', {
                data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        useCredits({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/use-credits', {
                data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        generateFreeBatch({state, dispatch}, data) {
            console.log(data);
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post(`/api/freeQCG/update-batch-status`,
            {...data} ,
            {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        getVectorImages({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/get-vector-images', {
                data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        getLabelsPreview({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.get(
                `/api/labels/download-preview/${data}`, 
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            )
        },
        checkOrder({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/check-order', {
                data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        refreshBatch({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/refreshBatch', {
                data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        getBatchDetails({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/getBatchDetails', {
                data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        downloadInvoice({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/invoice/download', {
                data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        downloadAccountData({state, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post('/users/account/data-download', {
                data
            }, {headers: {'Authorization': `JWT ${state.idToken}`}})
        },
        async getSavedTemplates({state, dispatch, commit}) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            } else {
                //loading toggle componenet restrict the user interactivity 
                // await commit('toggleLoadingProcess', true);
                const resp = axios.get(
                    '/fetch-templates',
                    {headers: {'Authorization': `JWT ${state.idToken}`}}
                );
                resp.then(async res => {
                    if (res.status === 200) {
                        await commit('getSavedTemplates', res.data.data);
                        // await commit('toggleLoadingProcess', false);
                    }
                }).catch(async err =>{
                    // await commit('toggleLoadingProcess', false);
                });
                return resp;
            }
        },
        async saveTemplate({state, commit, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            } else {
                //loading toggle componenet restrict the user interactivity
                // await commit('toggleLoadingProcess', true);
                const resp = axios.post(
                    '/save-templates',
                    {payload: data},
                    {headers: {'Authorization': `JWT ${state.idToken}`}}
                );
                resp.then(async res => {
                    if (res.status === 200) {
                        await dispatch('getSavedTemplates');
                        // await commit('toggleLoadingProcess', false);
                    }
                }).catch(async err =>{
                    // await commit('toggleLoadingProcess', false);
                });
                return resp;
            }
        },
        async deleteSavedTemplate({state, commit, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            } else {
                //loading toggle componenet restrict the user interactivity
                // await commit('toggleLoadingProcess', true);
                const resp = axios.post(
                    '/delete-templates',
                    {payload: data},
                    {headers: {'Authorization': `JWT ${state.idToken}`}}
                );
                resp.then(async res => {
                    if (res.status === 200) {
                        await dispatch('getSavedTemplates');
                        // await commit('toggleLoadingProcess', false);
                    }
                }).catch(async err =>{
                    // await commit('toggleLoadingProcess', false);
                    
                });
                return resp;
            }
        },

        async editSavedTemplateName({state, commit, dispatch}, data) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            } else {
                await commit('toggleLoadingProcess', true);
                const resp = axios.post(
                    '/save/template-name',
                    {payload: data},
                    {headers: {'Authorization': `JWT ${state.idToken}`}}
                );
                resp.then(async res => {
                    if (res.status === 200) {
                        await dispatch('getSavedTemplates');
                        await commit('toggleLoadingProcess', false);
                    }
                });
                return resp;
            }
        },
        updateDPI({state, dispatch}, data) {
            return axios.put(
                '/api/labels/update-dpi', 
                data,
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            );
        },
        updateLabelTextPosition({state, dispatch}, data) {
            return axios.put(
                '/api/labels/update-label-name-position', 
                data,
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            );
        },
        // customTextBatch
        saveCustomTextBatch({state, dispatch}, data) {
            
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            } 
                
            const response = axios.post(
                    'save/batch', 
                    {data: data},
                    {headers: {'Authorization': `JWT ${state.idToken}`}},
                );
            response.then(resp => {
                if(resp.status === 401)
                    dispatch('logout', {message: 'Please login again'});
                })
            return response;
        },
        async saveCanvasImg({state, dispatch}, data) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            } 
            const response =  axios.post(
                '/api/canvas/upload-base64img', 
                data,
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            );
            response.then(resp => {
                if(resp.status === 401)
                    dispatch('logout', {message: 'Please login again'});
                })
            return response;
        },

        createTemplate({state, dispatch}, data) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            } 
            const response =  axios.post(
                '/api/canvas/create-template', 
                // {payload: data},
                data,
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            );
            response.then(resp => {
                if(resp.status === 401)
                    dispatch('logout', {message: 'Please login again'});
                })
            return response;
        },

        saveBatchDetails({state, dispatch}, data) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            } 
            const response =  axios.post(
                '/api/canvas/save-batch-details', 
                data,
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            );
            response.then(resp => {
                if(resp.status === 401)
                    dispatch('logout', {message: 'Please login again'});
                })
            return response;
                
        },

        getDesignInfo({state, commit, dispatch}, data) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            if(data === 1) {
                return axios.get(
                    '/api/canvas/designs?category=CustomLabels', 
                    {headers: {'Authorization': `JWT ${state.idToken}`}},
                )

            }
            return axios.get(
                '/api/canvas/designs', 
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            )
                
        },
        getDesignObject({state, dispatch}, payload) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            } 
            const response =  axios.get(
                `/api/canvas/design/${payload}`, 
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            );
            response.then(resp => {
                if(resp.status === 401)
                    dispatch('logout', {message: 'Please login again'});
                })
            return response;
               
        },
        deleteCanvasTemplate({state, dispatch}, payload) {
            if (!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            const response = axios.delete(
                `/api/canvas/delete-template/${payload}`, 
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            );
            response.then(resp => {
                if(resp.status === 401)
                    dispatch('logout', {message: 'Please login again'});
                })
            return response;
        },
        getQRCodeURL({state, dispatch}, payload) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            } 
            const response = axios.get(
                `/api/canvas/get-qrcode/${payload}?format=png`, 
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            );
            response.then(resp => {
                if(resp.status === 401)
                    dispatch('logout', {message: 'Please login again'});
                })
            return response;
                
        },
        renameCanvasTemplate({state, dispatch}, payload) {
            
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            } 
            const response = axios.put(
                `/api/canvas/update-name/${payload.id}`, 
                payload.data,
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            );
            response.then(resp => {
                if(resp.status === 401)
                    dispatch('logout', {message: 'Please login again'});
                })
            return response;
           
        },
        getPreviewImage({state, dispatch}, payload) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            const response =   axios.get(
                `/api/canvas/download-sample/${payload.id}?preview=${payload.flag}`, 
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            );
            response.then(resp => {
                if(resp.status === 401)
                    dispatch('logout', {message: 'Please login again'});
                })
            return response;
            
        },
        getCanvasBatchDetails({state, dispatch}, payload) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            } 
            const response =   axios.get(
                `/api/canvas/get-batch-details/${payload}`, 
                {headers: {'Authorization': `JWT ${state.idToken}`}});
            response.then(resp => {
                if(resp.status === 401)
                    dispatch('logout', {message: 'Please login again'});
                })
            return response;
        },
        updateCustomBatchName({state, dispatch}, payload) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            } 
            return axios.put(
                `/api/canvas/update-batch-name`,
                payload,
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            )
        },
        // to update label layout incase user select a saved canvas template
        updateLabelLayout({state, dispatch}, payload) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            } 
            return axios.put(
                `/api/canvas/update-label-layout`,
                payload,
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            )
        },
        updateCustomeBatchExportFormat({state, dispatch}, payload) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            } 
            return axios.put(
                `/api/canvas/update-batch-export-format`,
                payload,
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            )
        },
        


        //API calls for Custom labels
        getCustomBatchLabelPreview({state, dispatch}, payload) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            const response =   axios.get(
                `/api/custom-labels/download-label/${payload}`,
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            );
            response.then(resp => {
                if(resp.status === 401)
                    dispatch('logout', {message: 'Please login again'});
                })
            return response;
        },

        downloadCustomBatchLabelPreview({state, dispatch}, payload) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            const response =   axios.get(
                `/api/custom-labels/preview/${payload}`, 
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            );
            response.then(resp => {
                if(resp.status === 401)
                    dispatch('logout', {message: 'Please login again'});
                })
            return response;
        },

        createDuplicateBatch({state, dispatch}, payload) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post(
                `/api/duplicate-batch/create-new`, 
                {
                    batch_id: payload
                },
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            );
        },
        exitAccount({state, commit, dispatch}, data) {
            axios.post('/logout', {
                token: state.idToken
            })
            dispatch('resetCreateBatchProcess');
            commit('clearAuthData');
            Cookies.remove('expirationDate');
            Cookies.remove('token');
            Cookies.remove('userId');
            Cookies.remove('vuex');
            router.push({redirect: window.location.href = process.env.VUE_APP_Website});
        },
        getTotalCredits({state, commit, dispatch}) {
            
            dispatch('getCredits').then(res => {
                if (res.data.status === 'success') {
                    commit('setTotalCredits', res.data.total_credits ? res.data.total_credits : 0)
                } else if (res.data.status === 'failure' || res.data.message === "No credits found") {
                    commit('setTotalCredits', 0)
                }
                }).catch(error => {
                })
        },

        validateGST({state, dispatch}, payload) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post(
                `/api/GST/verify-gst`, 
                {
                    gst_number: payload
                },
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            );
        },

        updateQRCodeDesignInfo({state, dispatch}, payload) {
            if(!state.idToken) {
                dispatch('logout', {message: 'Please login again'});
            }
            return axios.post(
                `/api/duplicate-batch/save-design-info`, 
                {
                    ...payload
                },
                {headers: {'Authorization': `JWT ${state.idToken}`}},
            );
        },
        marketEmailUnsub(state, payload) {
            return axios.post(
                `/api/marketing-Emails/unsub`, 
                {
                    ...payload
                },
            );
        }


             
    },
    getters: {
        user(state) {
            return state.user
        },
        isAuthenticated(state) {
            return state.idToken !== null
        },
        getIdToken(state) {
            return state.idToken
        },
        authError(state) {
            return state.authError
        }
    },
    modules: {createBatch, customText},
    plugins: [
        createPersistedState({
            paths: ['createBatch', 'customText'],
            storage: window.sessionStorage // {
            //     getItem: key => Cookies.get(key),
            //     setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: true }),
            //     removeItem: key => Cookies.remove(key)
            // }
        }),
    ],
})
