import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie';

import store from './store/store'

// import DashboardPage from './components/dashboard/Dashboard'
// import SignUpPage from './components/auth/SignUp'
// import SignInPage from './components/auth/SignIn'
// import VerifyEmail from './components/verifyEmail/VerifyEmail'
// import Authenticate from './components/verifyEmail/Authenticate'
// import TheBatches from './components/dashboard/MyBatches'
// import ResetPassword from './components/auth/ResetPassword'
// import ChangePassword from './components/auth/ChangePassword'
// import TheAuth from './components/auth/TheAuth'
// import ResendLink from './components/auth/ResendLink'
// import Support from './components/dashboard/TheSupport'
// import TheProfile from './components/dashboard/TheProfile'
// import CreateBatch from './components/dashboard/CreateBatch'
// import ThePricing from './components/dashboard/ThePricing'
// import TheCredits from "./components/dashboard/Credits";
// import SecurityCheck from "./components/auth/SecurityCheck";
// import ThankYou from "./components/createBatch/ThankYou";


Vue.use(VueRouter);

const routes = [
    {path: '/', redirect: '/login'},
    {
        path: '/', component: () => import(/* webpackChunkName: "TheAuth" */ './components/auth/TheAuth'),
        children: [
            {
                path: 'register',
                component: () => import(/* webpackChunkName: "SignUp" */ './components/auth/SignUp'),
                name: 'sign-up'
            },
            {
                path: 'login',
                component: () => import(/* webpackChunkName: "SignIn" */ './components/auth/SignIn'),
                name: 'login'
            },
            {
                path: 'reset-password',
                component: () => import(/* webpackChunkName: "ResetPassword" */ './components/auth/ResetPassword'),
                name: 'reset-password'
            },
            {
                path: 'resend-link',
                component: () => import(/* webpackChunkName: "ResendLink" */ './components/auth/ResendLink'),
                name: 'resend-link'
            },
            {
                path: 'change-password',
                component: () => import(/* webpackChunkName: "ChangePassword" */ './components/auth/ChangePassword'),
                name: 'change-password'
            },
            {
                path: 'verify-email',
                component: () => import(/* webpackChunkName: "VerifyEmail" */ './components/verifyEmail/VerifyEmail'),
                name: 'verify-email'
            },
            {
                path: 'authenticate',
                component: () => import(/* webpackChunkName: "Authenticate" */ './components/verifyEmail/Authenticate'),
                name: 'authenticate'
            },
            {
                path: 'security-check',
                component: () => import(/* webpackChunkName: "SecurityCheck" */ './components/auth/SecurityCheck'),
                name: 'security-check'
            },
            {
                path: 'unsubscribe',
                component: () => import(/* webpackChunkName: "SecurityCheck" */ './components/auth/Unsubscribe.vue'),
                name: 'unsubscribe'
            },
        ]
    },
    {
        path: '/dashboard/',
        component: () => import(/* webpackChunkName: "Dashboard" */ './components/dashboard/Dashboard'),
        children: [
            {
                path: 'pricing',
                component: () => import(/* webpackChunkName: "Pricing" */ './components/dashboard/ThePricing'),
                name: 'pricing'
            },
            {
                path: 'create-batch',
                component: () => import(/* webpackChunkName: "CreateBatch" */ './components/dashboard/CreateBatch'),
                name: 'create-batch'
            },
            {
                path: 'batches',
                component: () => import(/* webpackChunkName: "MyBatches" */ './components/dashboard/MyBatches'),
                name: 'my-batches'
            },
            {
                path: 'profile',
                component: () => import(/* webpackChunkName: "TheProfile" */ './components/dashboard/TheProfile'),
                name: 'dashboard-profile'
            },
            {
                path: 'support',
                component: () => import(/* webpackChunkName: "TheSupport" */ './components/dashboard/TheSupport'),
                name: 'dashboard-support'
            },
            {
                path: 'credits',
                component: () => import(/* webpackChunkName: "Credits" */ './components/dashboard/Credits'),
                name: 'dashboard-credits'
            },
            {
                path: 'thank-you/:id',
                component: () => import(/* webpackChunkName: "ThankYou" */ './components/createBatch/ThankYou'),
                name: 'thank-you'
            },

        ],
        beforeEnter(to, from, next) {
            const accessToken = Cookies.get('token');
            const expirationDate = Cookies.get('expirationDate');
            const userId = Cookies.get('userId');
            if (accessToken) {
                if (store.state.idToken) {
                    next()
                } else {
                    store.commit('setAuthTokenInfo', {accessToken, userId, expirationDate});
                    next()
                }
            }
            else {
                if(to.path === '/login'){
                    next();
                }
                else if(to.path === '/register'){
                    next();
                }

                else{
                    next({name: 'login'})
                }
            }
        }
    },
    { 
        path: '*', 
        component: () => import(/* webpackChunkName: "example" */ './components/dashboard/404Page.vue'), 
        name: '404' 
    }
];

export default new VueRouter({mode: 'history', routes})
