import axios from "axios";
export default {
    state: {
        pageDescription: {
            heading: 'Create batch',
            subheading: 'Create, design, and export batch of QR Codes',
        },
        batchGeneration: {
            type: 'original',
            originalBatchDetail: {},
            originalMapping: [],
        },
        step: 1,
        processCreateBatch: {
            selectedBatchType: {
                isCardSelected: false,
                selectedCard: '',
            },
            selectedQrCodeCategory: '',
            batchDetails: '',
        },
        step3: {

            // pageToView: 'review',
            // selectedTab: 'Upload File',
            // displayJsonDataForCsv: [
            //     // ["Device 001", "DFWJS-SJWIS-JSUWN-001"],
            //     // ["Device 002", "DFWJS-SJWIS-JSUWN-002"],
            //     // ["Device 003", "DFWJS-SJWIS-JSUWN-003"]
            //
            //     // ["Survey Link 1", "https://survey-link.com/0001"],
            //     // ["Survey Link 2", "https://survey-link.com/0002"],
            //     // ["Survey Link 3", "https://survey-link.com/0003"]
            //     [
            //          ["John Manager", "John", "ABC Enterprise", "Manager", "john@abcenterprise.com", "111-123456", "1234", "123", "Albany", "New York", "USA"],
            //          ["Dave Director", "Dave", "ABC Enterprise", "Director", "dave@abcenterprise.com", "111-123457", "1234", "123", "Minnesota", "New York", "USA"],
            //          ["Gru Assistant", "Gru", "ABC Enterprise", "Assistant", "gru@abcenterprise.com", "111-123458", "1234", "123", "Atlanta", "New York", "USA"]
            //     ]
            // ],
            // tableData: [],
            // isFileUploadSuccess: true,
            // uploadedFileName :'',
            // reviewBatch: {
            //     // selectColumn:[],
            //     // columnReviewed:[],
            //     // columnValue:[],
            //     // isSelectColumnDisabled:[],
            //     // optionsForColumnsInDataFile:[],
            //     // showSkippedColumns:true
            // },
        },
        batchID: '',
        uploadedBatchDetails: '',
        serialCodeBatchDetails: {
            // prefix: 'ABCXOO',
            // start: '1',
            // increment: '1',
            // end: '10',
        },
        randomCodeBatchDetails: {
            // numberOfRandomCodes:'1000',
            // numberOfCharacters:'15',
            // selected:[]
        },
        step4: '',
        // {
        // pageToView:'Choose Design',
        // // qrCodeBatch:this.qrSeriesPreview,
        // totalQrCodes:'40',
        // sampleQrCode:{
        //     serialCode:'abcD1234'
        // },
        // designInfo:{
        //     posterInfo:{
        //         qrLeft:50,
        //         qrTop:50,
        //         qrHeight:70,
        //         qrWidth:70,
        //         eyeShape:"Shape1",
        //         dataPattern:"round"
        //     },
        //     version:"1.5",
        //     type:"poster",
        //     posterurl:"./src/assets/qrCodeDesigns/qrCodebackgroundImage.png"
        // },

        // designInfo: {
        //     dataInfo:{
        //         pattern:"Round",
        //         gradientStyle:"None",
        //         startColor:"#4875B4",
        //         endColor:"#ffffff",
        //         logo:
        //             {
        //             url:"https://scanova-batch.s3.us-east-2.amazonaws.com/assets/linkedin-dark.png",
        //             excavated:true,
        //             size:4.666666666666667,
        //             angle:0,
        //             color:""
        //         }
        //     },
        //     backGroundColor:"#FFFFFF",
        //     eyeInfo:{
        //         TL:{
        //             innerEyeColor: "#4875B4",
        //             outerEyeColor:"#4875B4",
        //             shape:"Shape1"
        //         },
        //         TR:{
        //             innerEyeColor:"#4875B4",
        //             outerEyeColor:"#4875B4",
        //             shape:"Shape1"
        //         },
        //         BL:{
        //             innerEyeColor:"#4875B4",
        //             outerEyeColor:"#4875B4",
        //             shape:"Shape1"
        //         }
        //     },
        //     version:"1.5",
        //     type:"qrcode",
        //     errorCorrection:"H"
        // },
        // },
        step5: {
            // batchName:"1234567890123",
            // exportType:"Images",
            // imageFormat:"PNG",
            // totalQrCodes:40,
            // size:{
            //     size:"1",
            //     units:"inches"
            // }
        },
        selectDesignModal: {
            aspectRatioForCanvasSize: null,
            canvasHeight: '1',
            canvasWidth: '1',
            enterBatchName: '',
            isCanvasSizeLocked: false,
            png_Size: '1',
            selectedCustomImageFormat: '',
            selectedImageFormat: '',
            selectedLabelFormat: '',
            selectedLabelLayoutOption: null,
            selectedOptionForSizeImagePNG: 'inches',
            selectedOptionForSizeImageSVG: '300',
            selectedLabelPrintResolution: '72',
            selectedLabelTextPosition: 'Bottom',
            selectedLabelSheet: 'US',
            selectedLabelShape: 'Square'
        },
        // {
        // "enterBatchName":"",
        // "selectedImageFormat":"PNG",
        // "png_Size":1,
        // "selectedOptionForSizeImagePNG":"inches",
        // "selectedOptionForSizeImageSVG":"300",
        // "selectedLabelFormat":"",
        // "selectedLabelLayoutOption":null
        // }

        savedBatchTemplates: [],

        customImageDesignerTool: {
            pageToViewInTool: '',
            savedCustomDesigns: [],
            userCanvasHeight: '',
            userCanvasWidth: '',
            userCanvasExportFormat: '',
            userCanvasUnit: '',
            userQRCodeSize: '',
            canvasShape: 'normal',
        },
        sampleData: {},
        encodedData: {},
        totalCredits: 0,
    },

    actions: {
        resetCreateBatchProcess({commit}) {
            commit('updateStep', 1);
            commit('updateBatchID', '');
            commit('updateProcessCreateBatch', {
                selectedBatchType: {
                    isCardSelected: false,
                    selectedCard: '',
                },
                selectedQrCodeCategory: '',
                batchDetails: '',
            });
            commit('updateStep3', {});
            commit('updateSerialCodeBatchDetails', {});
            commit('updateRandomCodeBatchDetails', {});
            commit('updateStep4', '');
            commit('clearSavedTemplates');
            commit('updateStep5', {});
            commit('resetSelectDesignModal');
        },
    },
    mutations: {
        setTotalCredits(state, payload) {
            state.totalCredits = payload
        },
        setEncodedData(state, payload) {
            state.encodedData = payload;
        },
        setSampleData(state, payload) {
            state.sampleData = payload;
        },
        updatePageDescription(state, payload) {
            state.pageDescription = payload;
        },
        updateStep(state, step) {
            state.step = step;
        },
        updateProcessCreateBatch(state, payload) {
            state.processCreateBatch = payload;
        },
        updateProcessCreateBatchSelectedBatchType(state, payload) {
            state.processCreateBatch.selectedBatchType.selectedCard = payload;
        },
        updateStep3(state, payload) {
            state.step3 = payload;
        },
        updateStep3PageToView(state, payload) {
            state.step3.pageToView = payload;
        },
        updateStep3SelectedTab(state, payload) {
            state.step3.selectedTab = payload;
        },
        updateStep3DisplayJsonDataForCsv(state, payload) {
            state.step3.displayJsonDataForCsv = payload.displayJson;
            state.step3.qr_code_count = payload.qr_code_count;
        },
        updateStep3TableData(state, payload) {
            state.step3.tableData = payload;
        },
        updateStep3IsFileUploadSuccess(state, payload) {
            state.step3.isFileUploadSuccess = payload;
        },
        updateStep3UploadedFileName(state, payload) {
            state.step3.uploadedFileName = payload;
        },
        updateStep3ReviewBatch(state, payload) {
            state.step3.reviewBatch = payload;
        },
        updateStep3ReviewBatchSelectColumn(state, payload) {
            state.step3.reviewBatch.selectColumn = payload;
        },
        updateStep3ReviewBatchColumnReviewed(state, payload) {
            state.step3.reviewBatch.columnReviewed = payload;
        },
        updateStep3ReviewBatchColumnValue(state, payload) {
            state.step3.reviewBatch.columnValue = payload;
        },
        updateStep3ReviewBatchIsSelectedColumnDisabled(state, payload) {
            state.step3.reviewBatch.isSelectColumnDisabled = payload;
        },
        updateStep3ReviewBatchOptionsForColumnsInDataFile(state, payload) {
            state.step3.reviewBatch.optionsForColumnsInDataFile = payload;
        },
        updateStep3ReviewBatchShowSkippedColumns(state, payload) {
            state.step3.reviewBatch.showSkippedColumns = payload;
        },
        updateSerialCodeBatchDetails(state, payload) {
            state.serialCodeBatchDetails = payload;
        },
        updatePrefixInSerialCodeBatchDetails(state, payload) {
            state.serialCodeBatchDetails.prefix = payload;
        },
        updateStartInSerialCodeBatchDetails(state, payload) {
            state.serialCodeBatchDetails.start = payload;
        },
        updateIncrementInSerialCodeBatchDetails(state, payload) {
            state.serialCodeBatchDetails.increment = payload;
        },
        updateEndInSerialCodeBatchDetails(state, payload) {
            state.serialCodeBatchDetails.end = payload;
        },
        updateRandomCodeBatchDetails(state, payload) {
            state.randomCodeBatchDetails = payload;
        },
        updateNumberOfRandomCodesInRandomCodeBatchDetails(state, payload) {
            state.randomCodeBatchDetails.numberOfRandomCodes = payload;
        },
        updateNumberOfCharactersInRandomCodeBatchDetails(state, payload) {
            state.randomCodeBatchDetails.numberOfCharacters = payload;
        },
        updateSelectedInRandomCodeBatchDetails(state, payload) {
            state.randomCodeBatchDetails.selected = payload;
        },
        updateStep4(state, payload) {
            state.step4 = payload;
        },
        updateStep4Name(state, payload) {
            state.step4.pageToView = payload;
        },
        updateStep4qrCodeDesign(state, payload) {
            state.step4.qrCodeDesign = payload;
        },
        updateStep4PageToView(state, payload) {
            state.step4.pageToView = payload.page;
            state.step4.designInfo = payload.designInfo;
        },
        updateStep4DesignInfo(state, payload) {
            state.step4.designInfo = payload;
        },
        updateStep4BgTabInLogoDesign(state, payload) {
            state.step4.backgroundPageTabInLogoDesign = payload;
        },
        updateStep4LogoFileNameInLogoDesign(state, payload) {
            state.step4.logoFileName = payload;
        },
        updateStep4SampleQrCode(state, payload) {
            state.step4.sampleQrCode = payload;
        },
        updateStep4IsComingFromMyBatches(state, payload) {
            state.step4['isComingFromMyBatches'] = payload;
        },
        updateStep5(state, payload) {
            state.step5 = payload;
        },
        updateStep5Size(state, payload) {
            state.step5.size = payload
        },
        updateStep5TotalQrCodes(state, payload) {
            state.step5.totalQrCodes = payload
        },
        updateStep5BatchName(state, payload) {
            state.step5.batchName = payload
        },
        updateStep5CustomBatchExportFormat(state, payload) {
            state.step5.imageFormat = payload
        },
        updateDefaultDesignInfo(state, payload) {
            state.step4.designInfo = payload.designInfo
        },
        updateBatchID(state, payload) {
            state.batchID = payload.batch_id
        },
        updateSelectDesignModal(state, payload) {
            // console.log('in updateSelectDesignModal:::::',payload);
            if (payload.hasOwnProperty('enterBatchName')) {
                // console.log('payload.enterBatchName:::::',payload.enterBatchName);
                state.selectDesignModal.enterBatchName = payload.enterBatchName;
            }
            if (payload.hasOwnProperty('selectedImageFormat')) {
                // console.log('payload.selectedImageFormat:::::',payload.selectedImageFormat);
                state.selectDesignModal.selectedImageFormat = payload.selectedImageFormat;
            }
            if (payload.hasOwnProperty('png_Size')) {
                // console.log('payload.png_Size:::::',payload.png_Size);
                state.selectDesignModal.png_Size = payload.png_Size;
            }
            if (payload.hasOwnProperty('selectedOptionForSizeImagePNG')) {
                // console.log('payload.selectedOptionForSizeImagePNG:::::',payload.selectedOptionForSizeImagePNG);
                state.selectDesignModal.selectedOptionForSizeImagePNG = payload.selectedOptionForSizeImagePNG;
            }
            if (payload.hasOwnProperty('selectedOptionForSizeImageSVG')) {
                // console.log('payload.selectedOptionForSizeImageSVG:::::',payload.selectedOptionForSizeImageSVG);
                state.selectDesignModal.selectedOptionForSizeImageSVG = payload.selectedOptionForSizeImageSVG;
            }
            if (payload.hasOwnProperty('selectedLabelFormat')) {
                // console.log('payload.selectedLabelFormat:::::',payload.selectedLabelFormat);
                state.selectDesignModal.selectedLabelFormat = payload.selectedLabelFormat;
            }
            if (payload.hasOwnProperty('selectedLabelLayoutOption')) {
                // console.log('payload.selectedLabelLayoutOption:::::',payload.selectedLabelLayoutOption);
                state.selectDesignModal.selectedLabelLayoutOption = payload.selectedLabelLayoutOption;
            }
            if (payload.hasOwnProperty('selectedLabelPrintResolution')) { 
                state.selectDesignModal.selectedLabelPrintResolution = payload.selectedLabelPrintResolution;
            }
            if (payload.hasOwnProperty('selectedLabelTextPosition')) { 
                state.selectDesignModal.selectedLabelTextPosition = payload.selectedLabelTextPosition;
            }
            if (payload.hasOwnProperty('canvasHeight')) {
                state.selectDesignModal.canvasHeight = payload.canvasHeight;
            }
            if (payload.hasOwnProperty('canvasWidth')) {
                state.selectDesignModal.canvasWidth = payload.canvasWidth;
            }
            if (payload.hasOwnProperty('aspectRatioForCanvasSize')) {
                state.selectDesignModal.aspectRatioForCanvasSize = payload.aspectRatioForCanvasSize;
            }
            if (payload.hasOwnProperty('isCanvasSizeLocked')) {
                state.selectDesignModal.isCanvasSizeLocked = payload.isCanvasSizeLocked;
            }
            if (payload.hasOwnProperty('selectedCustomImageFormat')) {
                state.selectDesignModal.selectedCustomImageFormat = payload.selectedCustomImageFormat;
            }
            if (payload.hasOwnProperty('selectedLabelSheet')) {
                state.selectDesignModal.selectedLabelSheet = payload.selectedLabelSheet;
            }
            if (payload.hasOwnProperty('selectedLabelShape')) {
                state.selectDesignModal.selectedLabelShape = payload.selectedLabelShape;
            }
            if ((Object.keys(payload).length === 0 && payload.constructor === Object)) {
                state.selectDesignModal = payload;
            }
            // console.log(state.selectDesignModal);
        },
        resetSelectDesignModal(state) {
            state.selectDesignModal = {
                enterBatchName: '',
                selectedImageFormat: '',
                png_Size: '1',
                selectedOptionForSizeImagePNG: 'inches',
                selectedOptionForSizeImageSVG: '300',
                selectedLabelFormat: '',
                selectedLabelLayoutOption: 'a',
                canvasHeight: '5',
                canvasWidth: '5',
                aspectRatioForCanvasSize: null,
                isCanvasSizeLocked: false,
                selectedCustomImageFormat: '',
                selectedLabelPrintResolution: '72',
                selectedLabelTextPosition: 'Bottom',
                selectedLabelSheet: 'US',
                selectedLabelShape: 'Square'
            }
        },
        saveTemplate(state, payload) {
            state.savedBatchTemplates.push(payload);
        },
        getSavedTemplates(state, payload) {
            state.savedBatchTemplates = payload;
        },

        clearSavedTemplates(state) {
            state.savedBatchTemplates = [];
        },
        updatePageToViewInToolInCustomImageDesignerTool(state, payload) {
            state.customImageDesignerTool.pageToViewInTool = payload;
        },
        updatesavedCustomDesignsInCustomImageDesignerTool(state, payload) {
            state.customImageDesignerTool.savedCustomDesigns = payload
        },
        updateCustomImageDesignerToolShape(state, payload) {
            state.customImageDesignerTool.canvasShape = payload;
        },
        updateCanvasSettingCustomImageDesignerTool(state, payload) {
            state.customImageDesignerTool.userCanvasHeight = payload.height;
            state.customImageDesignerTool.userCanvasWidth = payload.width;
            state.customImageDesignerTool.userCanvasExportFormat = payload.exportFormat;
            state.customImageDesignerTool.userCanvasUnit = payload.unit;
            state.customImageDesignerTool.userQRCodeSize = payload.qrCodeSize;
        },
        setBatchGeneration(state, payload) {
            state.batchGeneration.type = payload;
        },
        setBatchGenerationDetail(state, payload) {
            state.batchGeneration.originalBatchDetail = payload;
        },
        setBatchGenerationMapping(state, payload) {
            state.batchGeneration.originalMapping = payload;
        }
    },
}
