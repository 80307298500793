<template>
<div class="height-100">

  <b-overlay
      
      :show="loadingProcess"
      rounded="lg"
      variant="transparent"
      opacity="0.9"
      class="height-100 overflow-x-hidden"
      >
    <div class="height-100">
      <router-view></router-view>
      <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
      <alert-box ref="alertBox"></alert-box>
      <center-popup ref="centerPopup"></center-popup>
    </div>
  </b-overlay>
</div>
</template>

<script>
export default {
  name: 'app',
  components: {
    AlertBox: () => import(/* webpackChunkName: 'AlertBox'*/ './components/widgets/AlertBox'),
    ConfirmDialogue: () => import(/* webpackChunkName: 'ConfirmDialogue'*/ './components/widgets/ConfirmDialogue'),
    CenterPopup: () => import(/* webpackChunkName: 'CenterPopup'*/ './components/widgets/CenterPopup.vue')
  },
  computed: {
    loadingProcess: {
      get() {
        return this.$store.state.loadingProcess;
      }
    }
  }
}
</script>

<style lang="scss">
@import "components/scss/_variable.scss";

body, html {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  height: 100%;
}

.overflow-x-hidden {
  overflow-x: hidden;
}
.h-100 {
  height: 100vh !important;
}

.height-100 {
  height: 100%;
}

.page-info {
  background-color: #f8f8f8;
}

.popover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
  border: none;
}

.popover-header {
  border: none !important;
}

.popover, .popover-header {
  color: $theme-text-color !important;
  font-family: Montserrat, sans-serif !important;
  background-color: #FFFFFF !important;
}

.popover:focus, .popover:active {
  border: none !important;
}

.border-success {
  border-color: $theme-text-color !important;
}

.bg-dark {
  background-image: $theme-color !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $theme-text-color !important;
  background-color: $theme-text-color !important;
}

.w-15 {
  width: 15%
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
  border-color: #adb5bd !important;
}

.progress-bar-color {
  background-color: $theme-color;
}

.progress {
  border-radius: 3.25rem;
}

.dropdown-toggle::after {
  display: none;
}

.popover-icon {
  color: $popover-icon;
}

.modal-backdrop {
  background-color: transparent;
}

.modal-content {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: #ffffff;
}

.modal-title {
  font-weight: bold;
}

.modal-header {
  color: $modal-header-text-color;
  background-image: $modal-header-background;
}

.modal-header .close {
  padding: 0.2rem 1rem;
}

.opacity-50 {
  opacity: 0.5;
}

.close {
  opacity: 1 !important;
  font-size: 3.5rem;
  font-weight: 500;
  color: $modal-header-text-color;
}

.close:hover {
  color: $modal-header-text-color;
}

.modal-footer {
  border: none;
  font-weight: 600;
  padding: 1rem 2rem;

}

.modal-header button:focus {
  outline: none;
  box-shadow: none;
}

.btn-outline-warning {
  color: $theme-color;
  border-color: $theme-color;
  background-color: $background-color;
  box-shadow: 0 5px 7px 0 rgba(184, 181, 181, 0.5);
}

.btn-outline-warning:hover,
.btn-outline-warning:active,
.btn-outline-warning:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active {
  color: $background-color;
  border-color: $hover-color;
  background-color: $hover-color;
  box-shadow: none;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active,
.btn-outline-secondary:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  color: $secondary-btn-clr;
  border-color: $secondary-btn-clr;
  background-color: $background-color;
  box-shadow: none;
}

.modal-footer .btn-secondary {
  background-color: $background-color;
  border-radius: $border-radius !important;
  border-color: $hover-color;
  color: $hover-color;
}

.modal-footer .btn-secondary:hover {
  background-color: $hover-color;
  color: $background-color;
}

.modal-footer .btn-primary {
  background-color: $secondary-button-color !important;
  border-radius: $border-radius !important;
  border-color: $hover-color;
  color: $background-color;
}

.modal-footer .btn-primary:hover {
  background-color: $hover-color !important;
  color: $background-color;
}

.modal-footer .btn {
  padding: 0.4rem 3rem;
}

.theme-bg {
  background-image: $theme-color;
}

.card-shadow {
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.text-color, .placeholder-text {
  color: #b9b9b9;
}

.text-grey {
  color: $text-grey !important;
}

.text-dark-on-grey {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-color:hover {
  color: $hover-color;
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-none:hover {
  text-decoration: none !important;
}

.link-text {
  color: $theme-text-color !important;
  text-decoration: none !important;
  pointer-events: all !important;
}

.white-link-text {
  color: #9B9B9B ;

}

.cursor-pointer-on-hover:hover {
  cursor: pointer;
}

.link-text:hover, .white-link-text:hover {
  color: $hover-color !important;
  text-decoration: underline !important;
}

.link-text-on-hover:hover {
  color: $theme-text-color !important;
  cursor: pointer;
}


.border-danger {
  border-color: $invalid-text !important;
}

.success-text {
  color: $success-color;
}

.error-text {
  color: $invalid-text;
  font-size: 14px;
}

.dropdown-item:active {
  color: $theme-color;
  text-decoration: none;
  background-color: transparent;
}

.input input {
  font: inherit;
  /*width: 91%;*/
  background-color: $background-color;
  padding: 10px 15px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  border-radius: $border-radius !important;
}

.input.inline input {
  width: auto;
}

.input .showPassword {
  position: absolute;
  bottom: 10px;
  right: 3%;
}

.input input:focus {
  outline: none;
  border: 1px solid #585858;
  background-color: $background-color;
}

.input.invalid input {
  border: 1px solid $invalid-text;
  background-color: $background-color;
}

.input.invalid label {
  color: $invalid-text;
}

.submit button {
  color: $background-color;
  padding: 10px 20px;
  font: inherit;
  cursor: pointer;
  background-color: $button-background;
  box-shadow: 0 3px 4px 0 rgba(182, 173, 173, 0.5);
  border-radius: $border-radius !important;
}

.btn-primary-color {
  background-color: $button-background;
}

.nav-pills .nav-link.active {
  background-color: $theme-text-color !important;
}

.nav-pills .nav-link {
  background-color: $tabs-In-active;
  color: #585858;
}

.submit button {
  outline: none;
}

.submit button:hover,
.submit button:active {
  background-color: $hover-color;
  border: 1px solid $hover-color;
  color: white;
}

.submit button[disabled],
.submit button[disabled]:hover,
.submit button[disabled]:active {
  border: 1px solid $hover-color;
  background-color: $disabled-button-background;
  color: rgba(255, 255, 255, 0.7);
  cursor: not-allowed;
}


.white-round-cap {
  color: $theme-color;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1;
}

.white-round-cap-desktop {
  left: 91%;
  top: 38%;
}

.white-round-cap-mobile {
  left: 45%;
  top: 93%;
}

.cursor {
  cursor: pointer !important;
}

.btn-secondary {
  color: $secondary-button-color !important;
  border: $secondary-button-border !important;
  background-color: $background-color !important;
}

.text-blackish {
  color: $text-blackish !important;
}

.text-theme {
  color: $theme-text-color !important;
}

.text-color-1 {
  color: $text-color-1 !important;
}

.text-color-2 {
  color: $text-color-2 !important;
}

.text-placeholder {
 color: $placeholder-color;
}

.w-48 {
  width: 48% !important;
}

.rounded-corners {
  border-radius: 0.5rem !important;
}

.bg-clr2 {
  background-color: $background-color-grey !important;
}
.bg-clr3 {
  background-color: $background-color-2 !important;
}
.bg-clr-white {
  background-color: #FFFFFF;
}

.bg-clr-theme {
  background-color: $border-color-hover;
}

.bg-clr-success {
  background-color: $success-color;
}
.font-12 {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-h1 {
  font-size: 2rem !important;
}
.cursorOnHover:hover {
  cursor: pointer;
}

.text-white {
  color: #FFFFFF !important;
}

.f-12 {
  font-size: 12px !important;
}

button:focus {
  box-shadow: none !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.button-background {
  background-color: $button-background !important;
}

.w-80 {
  width: 80% !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-regular {
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');;
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 540px) {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .font-xs-13 {
    font-size: 13px !important;
  }
  .font-xs-14 {
    font-size: 14px !important;
  }
  .font-xs-16 {
    font-size: 16px !important;
  }
  .font-weight-light-xs {
    font-weight: 300 !important;
  }
  .font-weight-bold-xs {
    font-weight: bold !important;
  }
  .flex-xs-column {
    flex-direction: column !important;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }
  .justify-content-xs-center {
    justify-content: center !important;
  }
  .justify-content-xs-around {
    justify-content: space-around !important;
  }
  .justify-content-xs-between {
    justify-content: space-between !important;
  }
  .d-xs-none {
    display: none !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .w-xs-100 {
    width: 100% !important;
  }
  .text-xs-center {
    text-align: center !important;
  }

  .modal-footer {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .btn-primary {
    width: 165px;
  }
}

@media only screen and (min-width: 769px) {
  .d-md1-none {
    display: none !important;
  }
  .d-md1-block {
    display: block !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }

}

.or-btn {
    height: 60px;
    width: 60px;
    align-items: center;
    justify-content: center;
  }

  .error-animation {
  height: 0;
  transition: height 0.25s ease-out;
  overflow: hidden;
}

.error-animation-active {
  height: 20px !important;
  // min-height: 20px;
  // max-height: 45px;
  transition:  all 0.25s ease-out !important;
}

.auth-heading {
  font-size: 1.2rem;
}

.f-14__weight-500 {
  font-size: 14px !important;
  font-weight: 500 !important;
}

//Heading text standard
.headline-h1 {
  font-size: 38px;
  font-weight: 400;
  text-transform: lowercase;
}
.headline-h1::first-letter {
  text-transform: uppercase;
}
.headline-h2 {
  font-size: 30px;
  font-weight: 500;
}
.headline-h3 {
  font-size: 24px;
  font-weight: 500;
}

.headline-h4 {
  font-size: 18px;
  font-weight: 600;
}

//Subtitle text standard
.subtitle-1 {
  font-size: 20px;
  font-weight: 400;
}
.subtitle-2 {
  font-size: 18px;
  font-weight: 500;
}
.subtitle-3 {
  font-size: 26px;
  font-weight: 500;
}

//Body text standard
.bodyText-1 {
  font-size: 16px;
  font-weight: 400;
}
.bodyText-2 {
  font-size: 16px;
  font-weight: 300;
}
.bodyText-3 {
  font-size: 14px;
  font-weight: 400;
}
.bodyText-4 {
  font-size: 14px;
  font-weight: 300;
}
.bodyText-5 {
  font-size: 12px;
  font-weight: 400;
}
.bodyText-6 {
  font-size: 12px;
  font-weight: 300;
}
.bodyText-7 {
  font-size: 14px;
  font-weight: 500;
}

//Button text standard
.buttonText-1 {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}
.buttonText-2 {
  font-size: 14px;
  font-weight: 500;
  text-transform: lowercase;
}
.buttonText-2::first-letter {
  text-transform: uppercase;
}
.buttonText-3 {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.buttonText-4 {
  font-size: 12px;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .bodyText-sm-1 {
  font-size: 14px;
  font-weight: 400;
}
}

.theme-text-underline {
  color: $theme-text-color !important;
  text-decoration: underline;
  pointer-events: all !important;
}

@media only screen and (max-width: 500px) {

  .w-sm-100 {
    width: 100% !important; 
  }
}

.box-border {
  border: 1px solid $border-color-1;
}

.border-radius-4 {
  border-radius: 4px;
}

//vue modal style
.modal-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.modal-header .modal-title {
  font-size: 16px;
  font-weight: 400;
}

.modal-header .close {
  font-size: 34px;
}

.modal-open .modal {
  backdrop-filter: blur(2px);
}

.modal-content {
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 0;
}

.modal-content::-webkit-scrollbar {
  width: 4px;
}

.modal-content::-webkit-scrollbar-track {
  background: $scrollBar-track; 
}
 
/* Handle */
.modal-content::-webkit-scrollbar-thumb {
  background: $button-background; 
  // height: 20px;
}

.lh-1 {
  line-height: 1 !important;
}

.fill-width {
  width: 100%;
      width: -moz-available;          /* WebKit-based browsers will ignore this. */
      width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
      width: stretch;
}

.bg-clr-grey {
  background: #f5f5f5;
}

.alert-color {
  color: $invalid-text;
}

//bootstrap popover style 
.popover-body {
  padding-top: 0 !important;
}

//svg hover active 
.svg-hover_active:hover svg path {
  fill: $theme-text-color;
}

.break-all-words {
  word-break: break-all;
}

</style>
