export default { 
    state: {
        customTextType: '',
        fontStyleToolbarActive: false,
        addTextBox: false,
        canvasBackgroundColor: '',
        fontFamily: 'serif',
        textColor: '#000000',
        qrCodeTextValueArray: {},
        qrCodeTextValue: [],
        fontSize: 14,
        imageModal: false,
        isDeleteable: false,
        savedCanvasDetails: {},
        savedBatchDetails: {},
        qrCodePosition: {
            top: 0,
            left: 0
        },
        editCanvasDesign: false,
        isExportSettingValid: true,
        step4: {

        }
    },
    mutations: {
        setStep4Size(state, payload) {
            state.step4.size = payload;
        },
        setCustomTextType(state, payload) {
            state.customTextType = payload;
        }, 
        setExportSettingValidity(state, payload) {
            state.isExportSettingValid = payload
        },
        setSavedBatchDetails(state, payload) {
            state.savedBatchDetails = payload
        },
        setEditCanvasDesignTrue(state) {
            state.editCanvasDesign = true;
        },
        setEditCanvasDesignFalse(state) {
            state.editCanvasDesign = false;
        },
        getSavedCanvasDetails(state, payload) {
            state.savedCanvasDetails = payload;
        },
        toogleFontStyleToolbarActive(state) {
            state.fontStyleToolbarActive = !state.fontStyleToolbarActive;
        },
        setCanvasBackgroundColor(state, payload) {
            state.canvasBackgroundColor = payload;
        },
        setTrueFontStyleToolbarActive(state) {
            state.fontStyleToolbarActive = true;
        },
        setIsDeleteable(state, payload) {
            state.isDeleteable = payload;
        },
        setFalseFontStyleToolbarActive(state) {
            state.fontStyleToolbarActive = false;
        },
        canvasAddTextBox(state, payload) {
            state.addTextBox = payload;
        },
        setFontFamily(state, payload) {
            state.fontFamily = payload;
        },
        setTextColor(state, payload) {
            state.textColor = payload;
        },
        setFontSize(state, payload) {
            state.fontSize = payload;
        },
        setQrCodeTextValueArray(state, payload) {
            // console.log(payload);
            // state.qrCodeTextValueArray[payload.key] = payload.text;
            state.qrCodeTextValueArray = payload;
        },
        setQrCodeTextValue(state, payload) {
            state.qrCodeTextValue = payload;
        },
        removeQrCodeTextValueArray(state, payload) {
            state.qrCodeTextValueArray = payload;
            ;
        },
        toggleImageModal(state) {
            state.imageModal = !state.imageModal;
        },
        setQRCodePosition(state, payload) {
            state.qrcodePosition.top = payload.top;
            state.qrcodePosition.left = payload.left;
        }

    },
    // actions: {
    //     saveCustomTextBatch({state}) {
    //         console.log(state.getIdToken());
    //     }
    // },
}