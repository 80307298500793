import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store/store'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import VueMixpanel from 'vue-mixpanel'
import Cookies from 'js-cookie';

import Vuelidate from "vuelidate";
import {BootstrapVue, BootstrapVueIcons} from "bootstrap-vue";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'


/* import specific icons */
import { faAngleDown, faRotateRight, faRotateLeft, faEllipsis} from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// vue-gtag 1.16.1 supports ga4 in vue2
import VueGtag from 'vue-gtag';

/* add icons to the library */
library.add(faAngleDown, faRotateRight, faRotateLeft, faEllipsis)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
//add flag icons
import FlagIcon from 'vue-flag-icon'


import mdiVue from 'mdi-vue'
import * as mdijs from '@mdi/js'

//gobal variable for svg color
Vue.prototype.$inactiveSvgColor =  "#E5E5E5";
Vue.prototype.$activeSvgColor =  "#6767E7";

Vue.use(mdiVue, {
  icons: mdijs
}, FlagIcon)

Vue.use(VueGtag, {
  config : {
    id : process.env.VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID
  }
});

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(Cookies)

Vue.use(VueMixpanel, {
  token: process.env.VUE_APP_Mixpanel,
  config: {
    debug: false
  }
})

Sentry.init({
  Vue,
  environment: process.env.VUE_APP_ENV,
  dsn: process.env.VUE_APP_Sentry,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
          "localhost",
          "app.qrbatch.io",
          "qb2.app.qa.scanova,io",
          "qb2.app.staging.scanova.io",
          "qb2.app.qa.s3.scanova.io"
        , /^\//],
    }),
  ],
  beforeSend(event, hint) {
    if (hint.originalException === "Timeout") return null;
    return event;
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.01,
});

Vue.directive('click-outside-action', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      var element = document.querySelector('.action-list')
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target) || el.contains(element))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

Vue.prototype.$states = [
  {text: 'Please select a state', value: null},
  {
    text: 'JAMMU AND KASHMIR', value: 'JAMMU AND KASHMIR'
  },
  {
    text: 'HIMACHAL PRADESH', value: 'HIMACHAL PRADESH'
  },
  {
    text:'PUNJAB', value: 'PUNJAB'
  },
  {
    text: 'CHANDIGARH', value: 'CHANDIGARH'
  },
  {
    text:'UTTARAKHAND', value:'UTTARAKHAND'
  },
  {
    text:'HARYANA', value:'HARYANA'
  },
  {
    text: 'DELHI', value: 'DELHI'
  },
  {
    text: 'RAJASTHAN', value: 'RAJASTHAN'
  },
  {
    text: 'UTTAR PRADESH', value: 'UTTAR PRADESH'
  },
  {
    text: 'BIHAR', value: 'BIHAR',
  },
  {
    text: 'SIKKIM', value: 'SIKKIM'
  },
  {
    text:'ARUNACHAL PRADESH', value:'ARUNACHAL PRADESH'
  },
  {
    text: 'NAGALAND', value: 'NAGALAND'
  },
  {
    text:'MANIPUR', value:'MANIPUR'
  },
  {
    text: 'MIZORAM', value: 'MIZORAM'
  },
  {
    text: 'TRIPURA', value: 'TRIPURA'
  },
  {
    text: 'MEGHALAYA', value: 'MEGHALAYA'
  },
  {
    text: 'ASSAM', value: 'ASSAM'
  },
  {
    text:'WEST BENGAL', value:'WEST BENGAL'
  },
  {
    text: 'JHARKHAND', value: 'JHARKHAND'
  },
  {
    text: 'ODISHA', value: 'ODISHA'
  },
  {
    text:'CHHATTISGARH', value:'CHHATTISGARH'
  },
  {
    text:'MADHYA PRADESH', value:'MADHYA PRADESH'
  },
  {
    text: 'GUJARAT', value: 'GUJARAT'
  },
  {
    text: 'DAMAN AND DIU', value: 'DAMAN AND DIU'
  },
  {
    text: 'DADRA AND NAGAR HAVELI', value: 'DADRA AND NAGAR HAVELI'
  },
  {
    text: 'MAHARASHTRA', value: 'MAHARASHTRA'
  },
  {
    text: 'KARNATAKA', value: 'KARNATAKA'
  },
  {
    text:'GOA', value:'GOA'
  },
  {
    text: 'LAKSHADWEEP', value: 'LAKSHADWEEP'
  },
  {
    text: 'KERALA', value: 'KERALA'
  },
  {
    text: 'TAMIL NADU', value: 'TAMIL NADU'
  },
  {
    text: 'PUDUCHERRY', value: 'PUDUCHERRY'
  },
  {
    text: 'ANDAMAN AND NICOBAR', value: 'ANDAMAN AND NICOBAR'
  },
  {
    text: 'TELANGANA', value: 'TELANGANA'
  },
  {
    text: 'ANDHRA PRADESH', value: 'ANDHRA PRADESH'
  },
  {
    text: 'LADAKH', value: 'LADAKH'
  },
  {
    text: 'OTHER TERRITORY', value: 'OTHER TERRITORY'
  }
];
Vue.prototype.$countries = [
  {text: 'Please select a country', value: null},
  {text: 'Afghanistan', value: 'AF'},
  {text: 'Åland Islands', value: 'AX'},
  {text: 'Albania', value: 'AL'},
  {text: 'Algeria', value: 'DZ'},
  {text: 'American Samoa', value: 'AS'},
  {text: 'Andorra', value: 'AD'},
  {text: 'Angola', value: 'AO'},
  {text: 'Anguilla', value: 'AI'},
  {text: 'Antarctica', value: 'AQ'},
  {text: 'Antigua and Barbuda', value: 'AG'},
  {text: 'Argentina', value: 'AR'},
  {text: 'Armenia', value: 'AM'},
  {text: 'Aruba', value: 'AW'},
  {text: 'Australia', value: 'AU'},
  {text: 'Austria', value: 'AT'},
  {text: 'Azerbaijan', value: 'AZ'},
  {text: 'Bahamas', value: 'BS'},
  {text: 'Bahrain', value: 'BH'},
  {text: 'Bangladesh', value: 'BD'},
  {text: 'Barbados', value: 'BB'},
  {text: 'Belarus', value: 'BY'},
  {text: 'Belgium', value: 'BE'},
  {text: 'Belize', value: 'BZ'},
  {text: 'Benin', value: 'BJ'},
  {text: 'Bermuda', value: 'BM'},
  {text: 'Bhutan', value: 'BT'},
  {text: 'Bolivia', value: 'BO'},
  {text: 'Bosnia and Herzegovina', value: 'BA'},
  {text: 'Botswana', value: 'BW'},
  {text: 'Bouvet Island', value: 'BV'},
  {text: 'Brazil', value: 'BR'},
  {text: 'British Indian Ocean Territory', value: 'IO'},
  {text: 'Brunei Darussalam', value: 'BN'},
  {text: 'Bulgaria', value: 'BG'},
  {text: 'Burkina Faso', value: 'BF'},
  {text: 'Burundi', value: 'BI'},
  {text: 'Cambodia', value: 'KH'},
  {text: 'Cameroon', value: 'CM'},
  {text: 'Canada', value: 'CA'},
  {text: 'Cape Verde', value: 'CV'},
  {text: 'Cayman Islands', value: 'KY'},
  {text: 'Central African Republic', value: 'CF'},
  {text: 'Chad', value: 'TD'},
  {text: 'Chile', value: 'CL'},
  {text: 'China', value: 'CN'},
  {text: 'Christmas Island', value: 'CX'},
  {text: 'Cocos (Keeling) Islands', value: 'CC'},
  {text: 'Colombia', value: 'CO'},
  {text: 'Comoros', value: 'KM'},
  {text: 'Congo', value: 'CG'},
  {text: 'Congo, The Democratic Republic of the', value: 'CD'},
  {text: 'Cook Islands', value: 'CK'},
  {text: 'Costa Rica', value: 'CR'},
  {text: 'Cote D\'Ivoire', value: 'CI'},
  {text: 'Croatia', value: 'HR'},
  {text: 'Cuba', value: 'CU'},
  {text: 'Cyprus', value: 'CY'},
  {text: 'Czech Republic', value: 'CZ'},
  {text: 'Denmark', value: 'DK'},
  {text: 'Djibouti', value: 'DJ'},
  {text: 'Dominica', value: 'DM'},
  {text: 'Dominican Republic', value: 'DO'},
  {text: 'Ecuador', value: 'EC'},
  {text: 'Egypt', value: 'EG'},
  {text: 'El Salvador', value: 'SV'},
  {text: 'Equatorial Guinea', value: 'GQ'},
  {text: 'Eritrea', value: 'ER'},
  {text: 'Estonia', value: 'EE'},
  {text: 'Ethiopia', value: 'ET'},
  {text: 'Falkland Islands (Malvinas)', value: 'FK'},
  {text: 'Faroe Islands', value: 'FO'},
  {text: 'Fiji', value: 'FJ'},
  {text: 'Finland', value: 'FI'},
  {text: 'France', value: 'FR'},
  {text: 'French Guiana', value: 'GF'},
  {text: 'French Polynesia', value: 'PF'},
  {text: 'French Southern Territories', value: 'TF'},
  {text: 'Gabon', value: 'GA'},
  {text: 'Gambia', value: 'GM'},
  {text: 'Georgia', value: 'GE'},
  {text: 'Germany', value: 'DE'},
  {text: 'Ghana', value: 'GH'},
  {text: 'Gibraltar', value: 'GI'},
  {text: 'Greece', value: 'GR'},
  {text: 'Greenland', value: 'GL'},
  {text: 'Grenada', value: 'GD'},
  {text: 'Guadeloupe', value: 'GP'},
  {text: 'Guam', value: 'GU'},
  {text: 'Guatemala', value: 'GT'},
  {text: 'Guernsey', value: 'GG'},
  {text: 'Guinea', value: 'GN'},
  {text: 'Guinea-Bissau', value: 'GW'},
  {text: 'Guyana', value: 'GY'},
  {text: 'Haiti', value: 'HT'},
  {text: 'Heard Island and Mcdonald Islands', value: 'HM'},
  {text: 'Holy See (Vatican City State)', value: 'VA'},
  {text: 'Honduras', value: 'HN'},
  {text: 'Hong Kong', value: 'HK'},
  {text: 'Hungary', value: 'HU'},
  {text: 'Iceland', value: 'IS'},
  {text: 'India', value: 'IN'},
  {text: 'Indonesia', value: 'ID'},
  {text: 'Iran, Islamic Republic Of', value: 'IR'},
  {text: 'Iraq', value: 'IQ'},
  {text: 'Ireland', value: 'IE'},
  {text: 'Isle of Man', value: 'IM'},
  {text: 'Israel', value: 'IL'},
  {text: 'Italy', value: 'IT'},
  {text: 'Jamaica', value: 'JM'},
  {text: 'Japan', value: 'JP'},
  {text: 'Jersey', value: 'JE'},
  {text: 'Jordan', value: 'JO'},
  {text: 'Kazakhstan', value: 'KZ'},
  {text: 'Kenya', value: 'KE'},
  {text: 'Kiribati', value: 'KI'},
  {text: 'Korea, Democratic People\'S Republic of', value: 'KP'},
  {text: 'Korea, Republic of', value: 'KR'},
  {text: 'Kuwait', value: 'KW'},
  {text: 'Kyrgyzstan', value: 'KG'},
  {text: 'Lao People\'S Democratic Republic', value: 'LA'},
  {text: 'Latvia', value: 'LV'},
  {text: 'Lebanon', value: 'LB'},
  {text: 'Lesotho', value: 'LS'},
  {text: 'Liberia', value: 'LR'},
  {text: 'Libyan Arab Jamahiriya', value: 'LY'},
  {text: 'Liechtenstein', value: 'LI'},
  {text: 'Lithuania', value: 'LT'},
  {text: 'Luxembourg', value: 'LU'},
  {text: 'Macao', value: 'MO'},
  {text: 'Macedonia, The Former Yugoslav Republic of', value: 'MK'},
  {text: 'Madagascar', value: 'MG'},
  {text: 'Malawi', value: 'MW'},
  {text: 'Malaysia', value: 'MY'},
  {text: 'Maldives', value: 'MV'},
  {text: 'Mali', value: 'ML'},
  {text: 'Malta', value: 'MT'},
  {text: 'Marshall Islands', value: 'MH'},
  {text: 'Martinique', value: 'MQ'},
  {text: 'Mauritania', value: 'MR'},
  {text: 'Mauritius', value: 'MU'},
  {text: 'Mayotte', value: 'YT'},
  {text: 'Mexico', value: 'MX'},
  {text: 'Micronesia, Federated States of', value: 'FM'},
  {text: 'Moldova, Republic of', value: 'MD'},
  {text: 'Monaco', value: 'MC'},
  {text: 'Mongolia', value: 'MN'},
  {text: 'Montserrat', value: 'MS'},
  {text: 'Morocco', value: 'MA'},
  {text: 'Mozambique', value: 'MZ'},
  {text: 'Myanmar', value: 'MM'},
  {text: 'Namibia', value: 'NA'},
  {text: 'Nauru', value: 'NR'},
  {text: 'Nepal', value: 'NP'},
  {text: 'Netherlands', value: 'NL'},
  {text: 'Netherlands Antilles', value: 'AN'},
  {text: 'New Caledonia', value: 'NC'},
  {text: 'New Zealand', value: 'NZ'},
  {text: 'Nicaragua', value: 'NI'},
  {text: 'Niger', value: 'NE'},
  {text: 'Nigeria', value: 'NG'},
  {text: 'Niue', value: 'NU'},
  {text: 'Norfolk Island', value: 'NF'},
  {text: 'Northern Mariana Islands', value: 'MP'},
  {text: 'Norway', value: 'NO'},
  {text: 'Oman', value: 'OM'},
  {text: 'Pakistan', value: 'PK'},
  {text: 'Palau', value: 'PW'},
  {text: 'Palestinian Territory, Occupied', value: 'PS'},
  {text: 'Panama', value: 'PA'},
  {text: 'Papua New Guinea', value: 'PG'},
  {text: 'Paraguay', value: 'PY'},
  {text: 'Peru', value: 'PE'},
  {text: 'Philippines', value: 'PH'},
  {text: 'Pitcairn', value: 'PN'},
  {text: 'Poland', value: 'PL'},
  {text: 'Portugal', value: 'PT'},
  {text: 'Puerto Rico', value: 'PR'},
  {text: 'Qatar', value: 'QA'},
  {text: 'Reunion', value: 'RE'},
  {text: 'Romania', value: 'RO'},
  {text: 'Russian Federation', value: 'RU'},
  {text: 'RWANDA', value: 'RW'},
  {text: 'Saint Helena', value: 'SH'},
  {text: 'Saint Kitts and Nevis', value: 'KN'},
  {text: 'Saint Lucia', value: 'LC'},
  {text: 'Saint Pierre and Miquelon', value: 'PM'},
  {text: 'Saint Vincent and the Grenadines', value: 'VC'},
  {text: 'Samoa', value: 'WS'},
  {text: 'San Marino', value: 'SM'},
  {text: 'Sao Tome and Principe', value: 'ST'},
  {text: 'Saudi Arabia', value: 'SA'},
  {text: 'Senegal', value: 'SN'},
  {text: 'Serbia and Montenegro', value: 'CS'},
  {text: 'Seychelles', value: 'SC'},
  {text: 'Sierra Leone', value: 'SL'},
  {text: 'Singapore', value: 'SG'},
  {text: 'Slovakia', value: 'SK'},
  {text: 'Slovenia', value: 'SI'},
  {text: 'Solomon Islands', value: 'SB'},
  {text: 'Somalia', value: 'SO'},
  {text: 'South Africa', value: 'ZA'},
  {text: 'South Georgia and the South Sandwich Islands', value: 'GS'},
  {text: 'Spain', value: 'ES'},
  {text: 'Sri Lanka', value: 'LK'},
  {text: 'Sudan', value: 'SD'},
  {text: 'Suritext', value: 'SR'},
  {text: 'Svalbard and Jan Mayen', value: 'SJ'},
  {text: 'Swaziland', value: 'SZ'},
  {text: 'Sweden', value: 'SE'},
  {text: 'Switzerland', value: 'CH'},
  {text: 'Syrian Arab Republic', value: 'SY'},
  {text: 'Taiwan, Province of China', value: 'TW'},
  {text: 'Tajikistan', value: 'TJ'},
  {text: 'Tanzania, United Republic of', value: 'TZ'},
  {text: 'Thailand', value: 'TH'},
  {text: 'Timor-Leste', value: 'TL'},
  {text: 'Togo', value: 'TG'},
  {text: 'Tokelau', value: 'TK'},
  {text: 'Tonga', value: 'TO'},
  {text: 'Trinidad and Tobago', value: 'TT'},
  {text: 'Tunisia', value: 'TN'},
  {text: 'Turkey', value: 'TR'},
  {text: 'Turkmenistan', value: 'TM'},
  {text: 'Turks and Caicos Islands', value: 'TC'},
  {text: 'Tuvalu', value: 'TV'},
  {text: 'Uganda', value: 'UG'},
  {text: 'Ukraine', value: 'UA'},
  {text: 'United Arab Emirates', value: 'AE'},
  {text: 'United Kingdom', value: 'GB'},
  {text: 'United States', value: 'US'},
  {text: 'United States Minor Outlying Islands', value: 'UM'},
  {text: 'Uruguay', value: 'UY'},
  {text: 'Uzbekistan', value: 'UZ'},
  {text: 'Vanuatu', value: 'VU'},
  {text: 'Venezuela', value: 'VE'},
  {text: 'Viet Nam', value: 'VN'},
  {text: 'Virgin Islands, British', value: 'VG'},
  {text: 'Virgin Islands, U.S.', value: 'VI'},
  {text: 'Wallis and Futuna', value: 'WF'},
  {text: 'Western Sahara', value: 'EH'},
  {text: 'Yemen', value: 'YE'},
  {text: 'Zambia', value: 'ZM'},
  {text: 'Zimbabwe', value: 'ZW'}
];
Vue.prototype.$countryTaxDetails = [
  {country: 'Australia', iso:'au', enum: 'au_abn', formatEnum: 'AU ABN', example: '12345678912'},
  {country: 'Australia', iso:'au', enum: 'au_arn', formatEnum: 'AU ARN', example: '123456789123'},
  {country: 'Austria', iso:'at', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'ATU12345678'},
  {country: 'Belgium', iso:'be', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'BE0123456789'},
  {country: 'Brazil', iso:'br', enum: 'br_cnpj', formatEnum: 'BR CNPJ', example: '01.234.456/5432-10'},
  {country: 'Brazil', iso:'br', enum: 'br_cpf', formatEnum: 'BR CPF', example: '123.456.789-87'},
  {country: 'Bulgaria', iso:'bg', enum: 'bg_uic', formatEnum: 'EU VAT', example: '123456789'},
  {country: 'Bulgaria', iso:'bg', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'BG0123456789'},
  {country: 'Canada', iso:'ca', enum: 'ca_bn', formatEnum: 'CA BN', example: '123456789'},
  {country: 'Canada', iso:'ca', enum: 'ca_gst_hst', formatEnum: 'CA GST HST', example: '123456789RT0002'},
  {country: 'Canada', iso:'ca', enum: 'ca_pst_bc', formatEnum: 'CA PST BC', example: 'PST-1234-5678'},
  {country: 'Canada', iso:'ca', enum: 'ca_pst_mb', formatEnum: 'CA PST MC', example: '123456-7'},
  {country: 'Canada', iso:'ca', enum: 'ca_pst_sk', formatEnum: 'CA PST SK', example: '1234567'},
  {country: 'Canada', iso:'ca', enum: 'ca_qst', formatEnum: 'CA QST', example: '1234567890TQ1234'},
  {country: 'Chile', iso:'cl', enum: 'cl_tin', formatEnum: 'CL TIN', example: '12.345.678-K'},
  {country: 'Croatia', iso:'hr', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'HR12345678912'},
  {country: 'Cyprus', iso:'cy', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'CY12345678Z'},
  {country: 'Czech Republic', iso:'cz', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'CZ1234567890'},
  {country: 'Denmark', iso:'dk', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'DK12345678'},
  {country: 'Estonia', iso:'ee', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'EE123456789'},
  {country: 'European Union', iso:'eu', enum: 'eu_oss_vat', formatEnum: 'EU OSS VAT', example: 'EU123456789'},
  {country: 'Finland', iso:'fi', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'FI12345678'},
  {country: 'France', iso:'fr', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'FRAB123456789'},
  {country: 'Georgia', iso:'ge', enum: 'ge_vat', formatEnum: 'GE VAT', example: '123456789'},
  {country: 'Germany', iso:'de', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'DE123456789'},
  {country: 'Greece', iso:'gr', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'EL123456789'},
  {country: 'Hong Kong', iso:'hk', enum: 'hk_br', formatEnum: 'HK BR', example: '12345678'},
  {country: 'Hungary', iso:'hu', enum: 'hu_tin', formatEnum: 'HU TIN', example: '12345678-1-23'},
  {country: 'Hungary', iso:'hu', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'HU12345678912'},
  {country: 'Iceland', iso:'is', enum: 'is_vat', formatEnum: 'IS VAT', example: '123456'},
  {country: 'India', iso:'in', enum: 'in_gst', formatEnum: 'IN GST', example: '12ABCDE3456FGZH'},
  {country: 'Indonesia', iso:'id', enum: 'id_npwp', formatEnum: 'ID NPWP', example: '12.345.678.9-012.345'},
  {country: 'Ireland', iso:'ie', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'IE1234567AB'},
  {country: 'Israel', iso:'il', enum: 'il_vat', formatEnum: 'IL VAT', example: '000012345'},
  {country: 'Italy', iso:'it', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'IT12345678912'},
  {country: 'Japan', iso:'jp', enum: 'jp_cn', formatEnum: 'JP CN', example: '1234567891234'},
  {country: 'Japan', iso:'jp', enum: 'jp_rn', formatEnum: 'JP RN', example: '12345'},
  {country: 'Latvia', iso:'lv', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'LV12345678912'},
  {country: 'Liechtenstein', iso:'li', enum: 'li_uid', formatEnum: 'LI UID', example: 'CHE123456789'},
  {country: 'Lithuania', iso:'lt', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'LT123456789123'},
  {country: 'Luxembourg', iso:'lu', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'LU12345678'},
  {country: 'Malaysia', iso:'my', enum: 'my_frp', formatEnum: 'MY FRP', example: '12345678'},
  {country: 'Malaysia', iso:'my', enum: 'my_itn', formatEnum: 'MT ITN', example: 'C 1234567890'},
  {country: 'Malaysia', iso:'my', enum: 'my_sst', formatEnum: 'MY SST', example: 'A12-3456-78912345'},
  {country: 'Malta', iso:'mt', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'MT12345678'},
  {country: 'Mexico', iso:'mx', enum: 'mx_rfc', formatEnum: 'MX RFC', example: 'ABC010203AB9'},
  {country: 'Netherlands', iso:'nl', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'NL123456789B12'},
  {country: 'New Zealand', iso:'nz', enum: 'nz_gst', formatEnum: 'NZ GST', example: '123456789'},
  {country: 'Norway', iso:'no', enum: 'no_vat', formatEnum: 'NO VAT', example: '123456789MVA'},
  {country: 'Poland', iso:'pl', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'PL1234567890'},
  {country: 'Portugal', iso:'pt', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'PT123456789'},
  {country: 'Romania', iso:'ro', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'RO1234567891'},
  {country: 'Russia', iso:'ru', enum: 'ru_inn', formatEnum: 'CA PST BC', example: '1234567891'},
  {country: 'Russia', iso:'ru', enum: 'ru_kpp', formatEnum: 'CA PST MC', example: '123456789'},
  {country: 'Saudi Arabia', iso:'sa', enum: 'sa_vat', formatEnum: 'SA VAT', example: '123456789012345'},
  {country: 'Singapore', iso:'sg', enum: 'sg_qst', formatEnum: 'SG GST', example: 'M12345678X'},
  {country: 'Singapore', iso:'sg', enum: 'sg_uen', formatEnum: 'SG UEN', example: '123456789F'},
  {country: 'Slovakia', iso:'sk', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'SK1234567891'},
  {country: 'Slovenia', iso:'si', enum: 'si_tin', formatEnum: 'SI TIN', example: '12345678'},
  {country: 'Slovenia', iso:'si', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'SI12345678'},
  {country: 'South Africa', iso:'za', enum: 'za_vat', formatEnum: 'ZA VAT', example: '4123456789'},
  {country: 'South Korea', iso:'kr', enum: 'kr_brn', formatEnum: 'KR BRN', example: '123-45-67890'},
  {country: 'Spain', iso:'es', enum: 'es_cif', formatEnum: 'ES CIF', example: 'A12345678'},
  {country: 'Spain', iso:'es', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'ESA1234567Z'},
  {country: 'Sweden', iso:'se', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'SE123456789123'},
  {country: 'Switzerland', iso:'ch', enum: 'ch_vat', formatEnum: 'CH VAT', example: 'CHE-123.456.789 MWST'},
  {country: 'Taiwan', iso:'tw', enum: 'tw_vat', formatEnum: 'TW VAT', example: '12345678'},
  {country: 'Thailand', iso:'th', enum: 'th_vat', formatEnum: 'TH VAT', example: '1234567891234'},
  {country: 'Ukraine', iso:'ua', enum: 'ua_vat', formatEnum: 'UA VAT', example: '123456789'},
  {country: 'United Arab Emirates', iso:'ae', enum: 'ae_trn', formatEnum: 'AE TRN', example: '123456789012345'},
  {country: 'United Kingdom', iso:'gb', enum: 'gb_vat', formatEnum: 'GB VAT', example: 'GB123456789'},
  {country: 'United Kingdom', iso:'gb', enum: 'eu_vat', formatEnum: 'EU VAT', example: 'XI123456789'},
  {country: 'United States', iso:'us', enum: 'us_ein', formatEnum: 'US EIN', example: '12-3456789'},
];

// Vue.use(VueMixpanel, {
//   // token: process.env.VUE_APP_Mixpanel
//   token: process.env.VUE_APP_Mixpanel
// });

Vue.mixin({
  methods: {
    googleAnalytics4Event(evtAct, evtDef, evtNom, evtSub = null) {
      this.$gtag.event(evtAct, {
        'Event_Definition'   : evtDef,
        'Event_Nomenclature' : evtNom,
        ...(evtSub && {'Sub_Event' : evtSub})
      });
    },
    changeObjectKeys(dataset, callback) {
      ['QR Code Name/Filename', 'fileNameForLabels'].forEach(e => delete dataset[e]);
      if(dataset['Text Line 1']) {
        dataset['text1'] = dataset['Text Line 1'];
        delete dataset['Text Line 1'];
      }
      if(dataset['Text Line 2']) {
        dataset['text2'] = dataset['Text Line 2'];
        delete dataset['Text Line 2'];
      }
      if(dataset['Text Line 3']) {
        dataset['text3'] = dataset['Text Line 3'];
        delete dataset['Text Line 3'];
      }
      if(dataset['Text Line 4']) {
        dataset['text4'] = dataset['Text Line 4'];
        delete dataset['Text Line 4'];
      }
      if(dataset['Text Line 5']) {
        dataset['text5'] = dataset['Text Line 5'];
        delete dataset['Text Line 5'];
      }
      if(dataset['Text Line 6']) {
        dataset['text6'] = dataset['Text Line 6'];
        delete dataset['Text Line 6'];
      }
      if(dataset['Text Line 7']) {
        dataset['text7'] = dataset['Text Line 7'];
        delete dataset['Text Line 7'];
      }
      callback(dataset);
    },
    getFormat(data, category) {
      if(category === 'Plain Text') {
        let dataObj;
        this.changeObjectKeys(data, function(result) {
          dataObj = result;
          let textArray = ['text1', 'text2', 'text3', 'text4', 'text5', 'text6', 'text7'];
          let textString = [];
          for (let i = 0; i < textArray.length; i++) {
            if (dataObj[textArray[i]] !== undefined) {
              textString.push(dataObj[textArray[i]]);
            }
          }
          dataObj.text = textString.join('\n');
        })
        data = dataObj
      }
      let config = {
        'Random Code': {
          start: '',
          dict: {
            'text': '#randomCode#'
          },
          end: '',
          seperator: '',
          spEncode: {}
        },
        'Serial Code': {
          start: '',
          dict: {
            'text': '#serialCode#'
          },
          end: '',
          seperator: '',
          spEncode: {}
        },
        'Plain Text': {
          start: '',
          dict: {
            // since all the text values are joined in a single text value above individual replacing the variables in not required
            // 'text': '#text1#\n#text2#\n#text3#\n#text4#\n#text5#\n#text6#\n#text7#'
            'text': '#text#'
          },
          end: '',
          seperator: '\n',
          spEncode: {}
        },
        'VCard': {
          start: 'BEGIN:VCARD\nVERSION:3.0\n',
          dict: {
            'name': 'N:#Lastname#;#Firstname#;;;',
            'company': 'ORG:#Department##Company#',
            'title': 'TITLE:#Title#',
            'phone': 'TEL;TYPE=work,voice:#Workphone#',
            'homePhone': 'TEL;TYPE=cell,voice:#Cellphone#',
            'fax': 'TEL;TYPE=work,fax:#Fax#',
            'website': 'URL:#Website#',
            'email': 'EMAIL:#Email#',
            'street': 'ADR:;;#Street#;#City#;#State#;#PostalCode#;#Country#'
          },
          end: 'END:VCARD',
          seperator: '\n',
          spEncode: function(data) {
            if(data.Department && data.Company ) {
              data.Department = data.Department + ', '
            } else if(data.Department && !data.Company) {
                data.Department = data.Department
            }
            for (const item in data){
              if(data[item].indexOf(';') > -1) {
                // let index = data[item].indexOf(';');
                // data[item] = data[item].substring(0, index) + '\\' + data[item].substring(index, data[item].length);
                data[item] = data[item].replace(/;/g,'\\;');
              } 
            }
            return data
          }
        },
        'Website URL': {
          start: '',
          dict: {
            'url': '#URL#',
          },
          end: '',
          seperator: '',
          spEncode: {}
        }
      };
      return config['' + category];
    },
    encode(category, data, forqrcode) {
      let cdata = data;
      let format = this.getFormat(data, category);
      let dict = format.dict;

      let qrString = format.start;

      /*preprocess data if required*/
      /*forqrcode shows that we will escape only when this is for generating qr code
          and not for saving in the db as in db we will save in original form*/
      if (forqrcode && typeof(format.spEncode.length) !== 'undefined') {
        cdata = format.spEncode(cdata);
      }

      /*in the value, remove all the ;:,etc that might occur in the ecoding*/
      for (let key in format.dict) {
        let dt = format.dict[key],
            numFound = 0;
        if (cdata[key]) {
          dt = dt.replace('#' + key + '#', cdata[key]);
        }
        //now run a loop to find if there are any other one's

        let others = dt.match(/#\w+#/g);
        if (others) {
          for (let i = 0; i < others.length; i++) {
            let thisKey = others[i].substring(1, others[i].length - 1);
            if (cdata[thisKey]) {
              dt = dt.replace(others[i], cdata[thisKey]);
              numFound++;
            }
          }
        }
        dt = dt.replace(/#\w+#/g, '');
        //only add if one of the above two were present
        if (cdata[key] || numFound !== 0) {
          qrString = qrString + dt + format.seperator;
        }

      }
      //remove the seperator added in the end in case format.end is not there
      if (format.end === '' && format.seperator !== '') {
        qrString = qrString.substring(0, qrString.length - 1);
      }
      qrString = qrString + format.end;

      return qrString;
    },
    rndStr(len) {
      let text = "";
      let chars = "abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return text
    },
    errorMessages(error) {
      // if (!error.response) {
      //   this.error = {
      //     message: 'Connection error. Refresh page and try again',
      //     type: 'general'
      //   };
      // } else
      if (error.response.status === 401) {
        this.error = {
          message: error.response.data.message,
          type: error.response.data.type,
          borderPassword: true
        };
        this.$store.dispatch('logout', {message: 'Please login again'});
      } else if (error.response.status === 400) {
        this.error = {
          message: error.response.data.message,
          type: 'password',
          borderPassword: true
        };
        // this.$store.dispatch('logout');
      } else if (error.response.status === 409) {
        this.error = {
          message: error.response.data.message,
          type: 'email',
          borderPassword: true
        }
      } else if (error.response.status === 406) {
        //used for Modal validation code
        this.error = {
          message: error.response.data.message,
          type: 'text',
          borderPassword: true
        }
      } else if(error.response.status === 429) {
        this.error = {
          message: 'Please try after 15 minutes.',
          type: 'text',
          borderPassword: false
        }
      }
    },
    fetchAPI() {
        this.$store.dispatch('fetchAPI').then(res => {
        this.$store.commit('setUserDetails', res.data);
        this.userData = res.data;
        this.notifyExpiry = !res.data.accountStatus;
      }).catch(error => {
        if(error) {
          if (error.response.status === 400) {
            this.$store.dispatch('logout', {message: 'Please login again'});
          } if(error.response.status === 403) {
            this.$root.$children[0].$refs.alertBox.show({
              type: 'error',
              message: `Validation error`
                             
            });
          }
          else {
            this.errorMessages(error);
          }
        } else {
          this.$store.dispatch('logout', {message: 'Please login again'});
        }
      })
    },
  }
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});
